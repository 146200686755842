import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`Update (11/19/2020): ColorCompatibility got a bump! Version 1.1.0 includes support for Objective-C. Thanks to `}<a parentName="p" {...{
          "href": "https://twitter.com/PierceMatthew"
        }}>{`Matthew Pierce`}</a>{` for the contribution.`}</p>
    </blockquote>
    <p>{`I `}<a parentName="p" {...{
        "href": "/blog/dark-mode-uicolor-compatibility"
      }}>{`wrote previously`}</a>{` about a technique I use in `}<a parentName="p" {...{
        "href": "https://itunes.apple.com/us/app/trestle-the-new-sudoku/id1300230302?mt=8"
      }}>{`Trestle`}</a>{` and `}<a parentName="p" {...{
        "href": "https://itunes.apple.com/us/app/cifilter-io/id1457458557?mt=8"
      }}>{`CIFilter.io`}</a>{` to support iOS 12 and lower while still using system provided, dark-mode accessible colors.`}</p>
    <p>{`Since I wrote that post, I've had a few people comment that the `}<inlineCode parentName="p">{`ColorCompatibility`}</inlineCode>{` code had issues, and I've updated it a few times, so today I decided to pull it out into its own library and GitHub repo! 🎉`}</p>
    <ul>
      <li parentName="ul">{`You can find the GitHub repo `}<a parentName="li" {...{
          "href": "https://github.com/noahsark769/ColorCompatibility"
        }}>{`here`}</a></li>
      <li parentName="ul">{`You can install the library via Cocoapods using:`}<pre parentName="li" {...{
          "className": "dark-default-dark vscode-highlight",
          "data-language": "ruby"
        }}><code parentName="pre" {...{
            "className": "vscode-highlight-code"
          }}><span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk1"
              }}>{`pod `}</span><span parentName="span" {...{
                "className": "mtk8"
              }}>{`'ColorCompatibility'`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{`, `}</span><span parentName="span" {...{
                "className": "mtk8"
              }}>{`'~> 1.0.0'`}</span></span></code></pre></li>
    </ul>
    <p>{`Hopefully this is a better home for the code. Please feel free to submit an issue or pull request `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/ColorCompatibility"
      }}>{`on GitHub`}</a>{` if you notice something that you think should be changed.`}</p>
    <p>{`Thanks to everyone who called out that there were errors in the original blog post - I really appreciate everyone in the community working to make the project more robust. 💪`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      